<template>
  <page-main
    title="Address Information."
    nextText="Leadership Skills"
    buttonTabIndex="11"
    eventName="nav"
    @nav="nav('/SoftSkills')"
    @save="save()"
  >
    <template v-slot:aside>
      <ul>
        <li>Let's continue with any relevant address information.</li>
        <li>
          It is not recommended to put your street address or apartment number-
          instead, simply limit the info you provide to a city, state, and
          country.
        </li>
      </ul>
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li class="mb-4">
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li>
                <span class="font-weight-bold">City:</span>
                Seattle
              </li>
              <li>
                <span class="font-weight-bold">State:</span>
                WA
              </li>
              <li>
                <span class="font-weight-bold">Country:</span>
                USA
              </li>
              <li>
                <span class="font-weight-bold">Zip Code:</span>
                98121
              </li>
              <li>
                <span class="font-weight-bold">Street Address (Optional):</span>
              </li>
              <li>
                <span class="font-weight-bold">Unit/Apt # (Optional):</span>
              </li>
            </ul>
          </li>
          <li class="mb-4">
            <span class="font-weight-bold">Example #2:</span>
            <ul>
              <li>
                <span class="font-weight-bold">City:</span>
                Seattle
              </li>
              <li>
                <span class="font-weight-bold">State:</span>
                Washington
              </li>
              <li>
                <span class="font-weight-bold">Country:</span>
                United States of America
              </li>
              <li>
                <span class="font-weight-bold">Zip Code:</span>
                98121
              </li>
              <li>
                <span class="font-weight-bold">Street Address (Optional):</span>
                100 Fake Name Road
              </li>
              <li>
                <span class="font-weight-bold">Unit/Apt # (Optional):</span>
                500
              </li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-text-field v-model="city" label="City" tabindex="1"></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="state"
            label="State"
            tabindex="2"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="country"
            label="Country"
            tabindex="3"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="zipCode"
            label="Zip Code"
            tabindex="4"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="addressLine1"
            label="Street Address (Optional)"
            tabindex="5"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="addressLine2"
            label="Unit/Apt # (Optional)"
            tabindex="6"
          ></r-text-field>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import Modal from "@/components/Modal.vue";

// @ is an alias to /src
export default {
  name: "Address",
  data: () => ({}),
  components: { Modal },
  mounted() {},
  methods: {
    save() {
      this.saveAddress();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("address", ["saveAddress"]),
  },
  computed: {
    ...mapFields("address", [
      "addressLine1",
      "addressLine2",
      "city",
      "state",
      "country",
      "zipCode",
    ]),
    tips() {
      return ["Many times recruiters do not need to see your full address."];
    },
  },
};
</script>
